import React, { createContext, useContext } from "react"
import compact from "lodash/compact"

export let languageOptions = [
  { label: "India - English", value: "en-in", lang: "en-IN" },
  { label: "United States - English", value: "en-us", lang: "en-US" },
  { label: "United Kingdom - English", value: "en-uk", lang: "en-GB" },
]

export const GlobalContext = createContext({
  pageContext: {},
  languageOptions,
  currentLang: "en-IN",
  currentRouteOption: {
    label: "India - English",
    value: "en-in",
    lang: "en-IN",
  },
})
export function GlobalContextProvider({ children, ...props }) {
  let pageContext = props?.pageContext
  let currentUrlArray = compact(pageContext.slug?.split("/"))
  let isLangRoute = languageOptions.some(
    (option) => option.value === currentUrlArray[0]
  )
  if (isLangRoute) {
    currentUrlArray.shift()
  }
  let currentLang = pageContext.langKey

  let currentRouteOption = languageOptions.filter(
    (option) => option.value === currentLang || option.lang === currentLang
  )[0]
  return (
    <GlobalContext.Provider
      value={{
        pageContext: { ...pageContext, url: `/${currentUrlArray.join("/")}` },
        languageOptions,
        currentLang,
        currentRouteOption,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
export default function useGlobal() {
  return useContext(GlobalContext)
}
