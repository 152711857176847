exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datacenter-js": () => import("./../../../src/pages/datacenter.js" /* webpackChunkName: "component---src-pages-datacenter-js" */),
  "component---src-pages-domains-domain-pricing-en-uk-js": () => import("./../../../src/pages/domains/domain-pricing.en-uk.js" /* webpackChunkName: "component---src-pages-domains-domain-pricing-en-uk-js" */),
  "component---src-pages-domains-domain-pricing-en-us-js": () => import("./../../../src/pages/domains/domain-pricing.en-us.js" /* webpackChunkName: "component---src-pages-domains-domain-pricing-en-us-js" */),
  "component---src-pages-domains-domain-pricing-js": () => import("./../../../src/pages/domains/domain-pricing.js" /* webpackChunkName: "component---src-pages-domains-domain-pricing-js" */),
  "component---src-pages-domains-in-domain-pricing-en-uk-js": () => import("./../../../src/pages/domains/in-domain-pricing.en-uk.js" /* webpackChunkName: "component---src-pages-domains-in-domain-pricing-en-uk-js" */),
  "component---src-pages-domains-in-domain-pricing-en-us-js": () => import("./../../../src/pages/domains/in-domain-pricing.en-us.js" /* webpackChunkName: "component---src-pages-domains-in-domain-pricing-en-us-js" */),
  "component---src-pages-domains-in-domain-pricing-js": () => import("./../../../src/pages/domains/in-domain-pricing.js" /* webpackChunkName: "component---src-pages-domains-in-domain-pricing-js" */),
  "component---src-pages-getmotherhostapp-js": () => import("./../../../src/pages/getmotherhostapp.js" /* webpackChunkName: "component---src-pages-getmotherhostapp-js" */),
  "component---src-pages-index-en-uk-js": () => import("./../../../src/pages/index.en-uk.js" /* webpackChunkName: "component---src-pages-index-en-uk-js" */),
  "component---src-pages-index-en-us-js": () => import("./../../../src/pages/index.en-us.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-submit-an-inquiry-js": () => import("./../../../src/pages/submit-an-Inquiry.js" /* webpackChunkName: "component---src-pages-submit-an-inquiry-js" */),
  "component---src-pages-support-report-false-whois-js": () => import("./../../../src/pages/support/report-false-whois.js" /* webpackChunkName: "component---src-pages-support-report-false-whois-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/BlogCategories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-help-index-js": () => import("./../../../src/templates/HelpIndex.js" /* webpackChunkName: "component---src-templates-help-index-js" */),
  "component---src-templates-hosting-js": () => import("./../../../src/templates/hosting.js" /* webpackChunkName: "component---src-templates-hosting-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-productlanding-page-js": () => import("./../../../src/templates/ProductlandingPage.js" /* webpackChunkName: "component---src-templates-productlanding-page-js" */),
  "component---src-templates-tlds-js": () => import("./../../../src/templates/tlds.js" /* webpackChunkName: "component---src-templates-tlds-js" */)
}

